@import '../../../styles/module-base.less';

@highlight-color: @white;

.ListItem:global(.ant-list-item) {
  border: 1px solid @input-border-color;
  cursor: pointer;
  white-space: nowrap;
  padding: 2px 10px;

  @media (min-width: @screen-lg) {
    padding: 10px 24px;
  }
}

.ListItem--Unread {
  background-color: @highlight-color;

  .ItemParticipants,
  .ItemDescription__Subject {
    font-weight: bold;
  }
}

.ItemDescription__Subject {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ItemDescription__Name {
  font-weight: bold;
  color: @blue-7;

  @media (min-width: @screen-lg) {
    &::after {
      content: '\00a0|\00a0';
    }
    &:empty::after {
      content: '';
    }
  }
}

:global(.DragSource--DraggingFrom) {
  .ListItem--Selected {
    background-color: rgba(@primary-color, 0.75);
  }
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media (min-width: @screen-lg) {
    gap: 20px;
  }
}

.AccountType {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 1;

  &:global(.ant-avatar) {
    background-color: #eeeeee;
    border: 1px solid black;
  }
}

.ItemDate {
  width: auto;
}

.ItemParticipants {
  width: 170px;
  overflow: hidden;
}

.ItemDescription {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
}

.ItemParticipantsAndDescription {
  overflow: hidden;
  line-height: 1.25rem;
  flex: 1;
}

.ItemDescription__Snippet {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: @color-grey-dark;

  @media (min-width: @screen-lg) {
    &::before {
      content: '\00a0-\00a0';
    }
    :empty + &::before {
      content: '';
    }
  }
}

.ItemAttachment {
  width: 20px;
  height: 11px;
}

.ItemAttachment--Active {
  background-image: url('../../../resources/img/icon-attachment.png');
  background-size: 20px 11px;
}

.ParticipantsAndDate {
  display: flex;
}

.IconIncoming {
  text-decoration: underline;
  border-bottom: 1px solid black;
  padding-bottom: 1px;
}

@import '../../../styles/module-base.less';
@import '../../../styles/tools.less';

.Container {
  .absolute();
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  background-image: url('../../../resources/img/background-1.png');

  @media (min-width: @screen-md) {
    padding-bottom: 55px;
  }
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;

  &::before {
    content: '';
  }

  @media (min-width: @screen-md) {
    justify-content: flex-start;
    padding-top: 30px;
  }
  @media (min-width: @screen-xl) {
    padding-top: 90px;
  }
}

.Card {
  width: 360px;
  max-width: 100%;
}

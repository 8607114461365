.Container {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  @media print {
    .Content,
    * {
      overflow: visible !important;
    }
  }
}

.Content {
  flex: 1;
  min-height: 0;
  overflow: auto;
}

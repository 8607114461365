.Popover {
  width: 450px;
  max-width: 95vw;
  min-height: 200px;
}

.Popover__Icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

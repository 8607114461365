.ant-divider--NoSpace {
  .ant-divider-inner-text {
    padding: 0;
  }
}

.ant-space.ant-space-vertical {
  width: 100%;
}

.ant-btn > span {
  vertical-align: middle;
}

.ant-btn.ant-btn-primary {
  border-width: 0;
}

span.anticon {
  .ant-btn:disabled > & {
    opacity: 0.25;
  }

  img {
    max-width: 1em;
    max-height: 1em;
  }
}

.ant-tabs.Tabs--Flex1InColumn {
  &,
  .ant-tabs-content-holder,
  .ant-tabs-content {
    display: flex;
    flex-direction: column;
  }
  &,
  .ant-tabs-content-holder,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    flex: 1;
    min-height: 0;
    height: auto;
  }

  .ant-tabs-tabpane {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.ant-layout-sider-collapsed .ant-affix {
  position: initial !important;
}

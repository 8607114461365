@import './module-base.less';

.normalize() {
  * {
    box-sizing: border-box;
  }

  html {
    font-family: @font-family;
    font-size: @font-size-base;
  }

  p {
    margin: 0;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button:focus {
    outline: none;
  }

  div > form {
    width: 100%;
  }

  button {
    cursor: pointer;
  }

  blockquote {
    margin: 1em 0;
  }

  mark:global(.SearchHighlight) {
    background-color: @yellow-4;
    padding: 3px;
  }
}

.normalize();

input,
select,
textarea {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      // Colors cannot be overriden, but we can make them animate so slow that they will never take effect
      transition: background-color 9999s ease-in-out 0s,
        color 9999s ease-in-out 0s;

      &:disabled {
        transition: none;
      }
    }
  }
}

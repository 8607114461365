.Checkbox--ClickAreaX3 {
  display: inline-flex;
  margin: -1em;
  padding: 1em;
  line-height: 1;

  > * {
    display: block;
  }
}

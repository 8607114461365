.RecurrenceToggle {
  display: 'flex';
  gap: '1rem';
}

.LastOccurenceData {
  display: flex;
  gap: 1rem;
}

.NumberOfOccurencesContainer {
  display: flex;
  gap: 1rem;
}

.NumberInput {
  width: 4rem;
}

.RecurrentEventContainer {
  align-items: center;
  text-align: left;
  gap: 0.5rem;
}

.EventShouldRepeatTextContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.FrequencyOptionsSelect {
  width: 8rem !important;
}

.FrequencyTypeContainer {
  display: flex;
  align-items: center;
}

.SelectedMonths {
  border-width: unset;
  width: 4rem;
  margin: 0.5rem 0;
}

.SelectedDays {
  border-width: unset;
}

@import '../../styles/email-display.less';

:root {
  --ck-color-base-border: @input-border-color;
  --ck-color-base-active: @input-hover-border-color;
  --ck-border-radius: 2px;
}

.ContainerBordered {
  :global(div.ck.ck-editor__editable) {
    &,
    &:global(.ck-focused) {
      border-width: 1px !important;
    }
  }
}

.Container {
  position: relative;

  div:global(.ck.ck-editor) {
    display: flex;
    flex-direction: column-reverse;
  }
  :global(div.ck.ck-editor__editable) {
    &,
    &:global(.ck-focused) {
      border-width: 0;
      outline-width: 0;
      box-shadow: none;
    }
    blockquote {
      .email-quote();
    }
    pre[data-block='true'] {
      .email-code();
    }
  }
  :global(.ck.ck-sticky-panel__content) {
    display: flex;
    justify-content: center;
  }
  :global(.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar) {
    border-radius: 6px;
    border-width: 1px;
  }
}

.Container--Flex1 {
  &,
  :global(.ck.ck-editor),
  :global(.ck.ck-editor__main) {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  :global(.ck.ck-editor__editable) {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
  }
}

.ToolbarParent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.Toolbar {
  text-align: right;
}

.Toolbar__After {
  display: flex;
}

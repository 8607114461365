@import '../../../styles/module-base.less';

.Container {
  background-color: @body-background;
  white-space: nowrap;
}

.Item {
  display: flex;
  flex-direction: column;
}

.InlineComposer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.InlineComposer--Email {
  min-height: 600px;
  max-height: 100vh;
  height: 60vh;
}

.DividerContainer {
  background-color: @body-background;
  overflow: hidden;

  .Container--Email & {
    &:not(:last-child) {
      border-bottom: 5px solid @input-border-color !important;
    }
  }
}

@import '../styles/module-base.less';

:root {
  // overrides
  // https://github.com/fullcalendar/fullcalendar/blob/master/packages/common/src/styles/vars.css
  --fc-button-text-color: @white;
  --fc-button-bg-color: @primary-color;
  --fc-button-border-color: @primary-color;
  --fc-button-hover-bg-color: @primary-color-hover;
  --fc-button-hover-border-color: @primary-color-hover;
  --fc-button-active-bg-color: @primary-color-active;
  --fc-button-active-border-color: @primary-color-active;
}

:global(.fc) {
  min-width: 500px;
  a {
    color: @text-color;
  }
  th {
    font-weight: normal;
  }
  :global(.fc-button):focus {
    box-shadow: none !important;
  }
}

@import '../styles/module-base.less';

.LabelsContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.LabelsContainer--NoClip {
  flex-wrap: wrap;
}

.Label {
  display: block;
  white-space: break-spaces;
  white-space: nowrap;
}

.Label--UseEllipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Label__Delimiter {
  white-space: pre;
}

.NumericOverflow {
  color: @color-grey-dark;
  font-weight: 400;
  font-size: 0.9em;
}

.CollapseIcon {
  margin-left: 5px;
}

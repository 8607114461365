@import '../../../styles/email-display.less';
@import '../../../styles/module-base.less';
@import '../../../styles/normalize.less';

.Iframe {
  width: 100%;
  border-width: 0;
}

.Content {
  .normalize();
  font-family: @font-family;
  font-size: @font-size-base;

  blockquote {
    .email-quote();
  }
  code {
    .email-code();
  }
}

@import '../../../styles/module-base.less';

.Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @color-grey-light;
  padding: 0 10px 4px 10px;
}

.Content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

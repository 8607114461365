@import '../../../styles/module-base.less';

.Container {
  width: 95vw;
  justify-content: space-around;
  padding-bottom: 1rem;

  @media (min-width: @screen-lg) {
    width: initial;
    justify-content: initial;
    padding-bottom: 0;
  }
}

.Container--Compact {
  width: initial;
  justify-content: initial;
  padding-bottom: 0;
}

.Button--Blue {
  color: @blue-6;

  img {
    // https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(46%) sepia(71%) saturate(3560%) hue-rotate(193deg)
      brightness(103%) contrast(104%);
  }
}

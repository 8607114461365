.Container {
  :global(.ant-form-item) {
    margin-bottom: 16px;
  }
  :global(.ant-form-item-label) {
    padding-bottom: 0;
    > label {
      height: auto;
    }
  }
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@import '../../../styles/module-base.less';

.MenuIcon {
  color: @primary-color;
}

.SubHeader {
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1rem;
}

.SubHeaderContent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100vw;
}

.RightCornerNodes {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.MainHeaderContent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1rem;
}

.HeaderContentRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.25rem;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.PageHeaderContent {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  width: 100%;
  flex-direction: row;
  align-items: baseline;

  > * {
    min-width: 48px;
  }
}

.HeaderRow {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
}

.SidebarToggle {
  color: @primary-color;
}

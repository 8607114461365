@import '../../../styles/module-base.less';

.Container {
  background-color: @body-background;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

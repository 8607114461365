.Container {
  :global(.ant-form-item) {
    margin-bottom: 16px;
  }
  :global(.ant-form-item-label) {
    padding-bottom: 0;
    > label {
      height: auto;
    }
  }
}

.RangeIcon {
  opacity: 0.5;
}

.RangeIcon--Enabled {
  opacity: 1;
}

.Affix {
  background-color: white;
  padding: 8px 10px;
}

.Container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

@import '../../../styles/module-base.less';

.Avatar:global(.ant-avatar-lg) {
  @size: 150px;
  width: @size;
  height: @size;
  line-height: @size;
}

.LabelRow {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.Label {
  color: @color-grey-dark;
}

.LabelValue {
  flex: 1;
  min-width: 0;
  font-weight: bold;
}

@import '../../../styles/module-base.less';
@import '../../../styles/normalize.less';
@import '../../../styles/tools.less';

.Content {
  .normalize();
  font-family: @font-family;
  font-size: @font-size-base;

  white-space: pre-line;

  > img,
  video,
  audio {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    max-height: 50vh;
    object-fit: contain;
    object-position: left;
  }
}

.Content--Failed {
  font-style: italic;
  opacity: 0.5;
}

.InlineFile--Failed {
  position: relative;
  border: 2px solid @color-grey-verydark;
  border-radius: 20px;
  margin: 10px 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;

  :global(.anticon) {
    font-size: 50px;
    color: rgba(@primary-color, 0.83);
  }
}

.InlineFile--Failed__Thumbnail {
  .absolute(0);
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.25;
  filter: blur(1px);
}

.InlineFile--Failed__Events {
  align-self: stretch;
  text-align: left;
  border: 1px solid @color-grey-verydark;
  padding: 5px;
  font-family: monospace;
}

.InlineFile--Failed__Error {
  font-weight: bold;
  color: @red-6;
}

@import '../../../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
}

.Logo {
  width: @app-siderbar-width;
}

.Menu {
  flex: 1;
  min-width: 0;
}

.Actions {
  margin-right: 30px;
}

.MenuIcon {
  color: @primary-color;
  align-self: center;
  justify-self: center;
  padding-bottom: 1rem;
}

@import '../styles/module-base.less';

:root {
  --custom-checkbox-color: @primary-color;
}
@color: var(--custom-checkbox-color);
:global(.ant-checkbox-wrapper).Container:hover :global(.ant-checkbox-inner) {
  border-color: @color;
}
.Container {
  :global(.ant-checkbox)::after {
    border-color: @color;
  }
  :global(.ant-checkbox .ant-checkbox-inner) {
    border-color: @color;
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: @color;
  }
  :global(.ant-checkbox-input):focus + :global(.ant-checkbox-inner) {
    border-color: @color;
  }
}

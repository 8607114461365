@import '../../../styles/module-base.less';

.Pagination {
  background-color: rgba(white, 0.9);
  padding: 0.25rem 0.5rem;
  border-radius: 0.65rem;

  @media (min-width: @screen-md) {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }
}

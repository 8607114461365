@import '../../../../../styles/module-base.less';

.FormItem--Compact {
  margin-bottom: 0;
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: @color-grey-medium;
  margin: 5px 0;
}

.ForwardPreview {
  border-left: 4px solid @color-grey-dark;
  padding: 5px 10px;
}

.Messages {
  display: flex;
  flex-direction: column;
}

.BodyAndFile {
  padding: 10px;
  display: flex;
  gap: 10px;

  flex-direction: column;
  align-items: stretch;
  @media (min-width: @screen-md) {
    flex-direction: row;
    align-items: flex-start;
  }

  &:nth-child(odd) {
    background-color: rgba(@color-grey-light, 0.5);
  }
  &:nth-child(even) {
    background-color: white;
  }
}

.TemplatePreviewContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.TemplatePlaceholderField {
  margin-bottom: 5px;
}

/* Style for message container */
.TemplateContainer {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(@color-grey-light, 0.5);

  // Style for message header
  .TemplateHeader {
    font-weight: bold;
    margin-bottom: 5px;
  }

  // Style for message body
  .TemplateBody {
    margin-bottom: 5px;
  }

  // Style for message footer
  .TemplateFooter {
    font-size: 12px;
    color: #999;
  }

  // Style for buttons
  .TemplateButtons {
    display: flex;
    flex-direction: column;
    .TemplateButton {
      margin: 2px 0 2px 0;
      text-align: center;
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid #216eeb;
      border-radius: 4px;
      background-color: #e0e0e0;
      color: #216eeb;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: #80807e;
      }
    }
  }
}

.EditorWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4em;
  gap: 10px;
  max-width: 800px;
  flex: 1;
}

.TemplateBodyField {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  max-width: 800px;
  flex: 1;
}

.FilePreview {
  position: relative;
  min-width: 0;
  max-width: 800px;
  min-height: 0;
  max-height: 40vh;
  display: flex;
  align-self: flex-start;
  border: 1px solid rgba(@primary-color, 0.5);
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    border-color: @primary-color;
  }

  > img,
  video,
  audio {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.FilePreview__Close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  color: @red-4;

  &:hover {
    color: @red-6;
  }
}

textarea.Body {
  flex: 1;
}

.SendIcon {
  color: @primary-color;
}

.BlueText {
  color: blue;
}

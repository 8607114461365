@import '../../../styles/module-base.less';

.List {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  overflow-y: auto;
}

.ErrorLoading {
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ErrorLoading__Account {
  display: flex;
  align-items: center;
  gap: 8px;
}

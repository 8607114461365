@import '../../styles/module-base.less';

.SimpleFooter {
  font-family: 'Roboto', sans-serif;
  color: @text-color-secondary;
}

.SimpleFooter--Bottom {
  margin-top: 20px;

  @media (min-width: @screen-md) {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}

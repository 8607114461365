@import '../../styles/module-base.less';
@import '../../styles/tools.less';

.Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 5px;

  @media (min-width: @screen-lg) {
    .absolute(0);
    padding: 20px 10px;
  }
}

.SiderInner {
  padding-top: 1rem;

  @media (min-width: @screen-lg) {
    padding-top: 0;
  }
}

.Menu {
  width: 100%;
  max-width: 100%;
}

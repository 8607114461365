.FullSize {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Flex {
  display: flex;
}

.Flex--Center {
  align-items: center;
  justify-content: center;
}

.FullWidth {
  width: 100%;
}

.FullWidth--InFlexVertical {
  align-self: stretch;
}

.Flex--1 {
  min-width: 0;
  min-height: 0;
  flex: 1;
}

.Overflow--Auto {
  overflow: auto;
}

.FlexRow {
  display: flex;
  align-items: center;

  span& {
    display: inline-flex;
  }
}

.FlexRow--SpaceSm {
  gap: 5px;
}

.FlexRow--SpaceMd {
  gap: 10px;
}

.FlexRow--SpaceLg {
  gap: 20px;
}

.FlexCol {
  display: flex;
  flex-direction: column;
}

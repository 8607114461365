@import '../../../styles/module-base.less';

.Container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
  background-color: white;
}

.Card {
  font-size: @font-size-lg;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  padding: 10px 12px;
  background-color: white;
  border: 1px solid @text-color;
  box-shadow: 1px -1px 6px 2px rgba(@text-color, 0.75);

  :global(.ant-btn.ant-btn-circle) {
    background-color: transparent;
    border-width: 0;
    &:global(.ant-btn-sm) {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
}

.Card--Error {
  color: @red-6;
}

.ActionLink {
  cursor: pointer;
  line-height: 1;
  color: @blue-6;
}

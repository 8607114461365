@import '../../../styles/module-base.less';

.Outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 2;
}
.Inner {
  max-width: 800px;
  background-color: @body-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 15px;
  text-align: center;
}
.InfoText {
  font-size: @font-size-lg;
  text-align: justify;

  > :global(.anticon) {
    color: @yellow-7;
    font-size: 32px;
  }
}

@overlay-background: rgba(255, 255, 255, 0.45);

.Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: @overlay-background;
}

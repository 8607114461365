@import '../../../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: column;
  background: @body-background;
  width: 100%;

  > * {
    padding-left: 10px;
    padding-right: 15px;
  }
}

.TopRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid @input-border-color;
  padding: 10px;
  gap: 10px;
}

.TopRow__Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  min-width: 0;
  line-height: 1;
}

.AccountInfoRow {
  padding: 5px 0 0 10px;

  @media (min-width: @screen-lg) {
    padding: 0;
  }
}

.ParticipantsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
}

.BottomRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}

.Dropdown {
  padding: 0 0.5rem;
}

.MobileHeaderInfo {
  line-height: 1;

  span {
    display: inline-block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.NameEditor {
  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  :global(.ant-form-item-label) {
    padding: 0;
  }
}

.NameInput {
  max-width: 250px;
}

@import '../../../styles/module-base.less';

.Actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
}

.Accounts {
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
}

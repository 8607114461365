@import '../../../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: @body-background;
  padding: 10px;
  height: 42px;

  @media (min-width: @screen-lg) {
    height: 32px;
    padding: 0 18px;
  }
}

.Left {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 7px;
}

.Right {
  display: flex;
  align-items: center;
  gap: 7px;
  position: absolute;
  bottom: 1rem;
  z-index: 10;
  border-radius: 0.5rem;
  width: 100%;
  left: 0;
  justify-content: center;

  @media (min-width: @screen-md) {
    position: initial;
    bottom: initial;
    z-index: initial;
    border-radius: 0;
    width: auto;
    left: initial;
    justify-content: initial;
  }
}

@import '../../styles/email-display.less';
@import '../../styles/tools.less';

.Button {
  width: 220px;
  .square();
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed @green-5;
  background-color: rgba(@green-5, 0.15);

  &:hover {
    background-color: rgba(@green-5, 0.25);
  }

  :global(.anticon) {
    font-size: @heading-1-size;
  }
}

.Button__Close {
  position: absolute;
  top: -14px;
  right: -5px;
  transform: translate(50%, -50%);
  color: @red-4;

  &:hover {
    color: @red-6;
  }
}

@import '../../../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: @body-background;
  padding: 10px;
  height: 42px;

  @media (min-width: @screen-md) {
    justify-content: space-between;
  }
  @media (min-width: @screen-lg) {
    height: 32px;
    padding: 0 18px;
  }
}

.Controls {
  display: flex;
  align-items: center;
  gap: 7px;

  @media (min-width: @screen-md) {
    flex: initial;
  }
}

.ActiveView {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  position: absolute;
  left: 0;
  bottom: 1rem;
  z-index: 10;
  border-radius: 0.5rem;
  width: 100%;

  @media (min-width: @screen-md) {
    position: initial;
    bottom: initial;
    z-index: initial;
    border-radius: 0;
    width: auto;
    left: initial;
    justify-content: initial;
  }
}

.SelectionLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  color: @color-grey-medium;
}

.SelectAllText {
  padding: 5px 0;
}

.DropdownButton {
  padding: 0;
}

.SelectAllButton {
  display: flex;
  flex-direction: column;
  padding: 0;
}

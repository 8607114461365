@import '../../styles/module-base.less';

.ButtonContainer {
  position: absolute;
  right: 10px;
  bottom: 10px;

  @media (min-width: @screen-md) {
    bottom: 10px;
  }
}

.Button__ErrorIcon {
  color: @red-6;
  font-size: 32px;
  margin: 1rem;
  border: 5px solid white;
  border-radius: 80px;
  cursor: pointer;

  &:hover {
    color: @red-6;
    font-size: 38px;
  }
}

.Transition {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
}

.OptionIcons {
  font-size: 36px;
}

.Icon {
  color: inherit;
}

.IconContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  padding: 1rem;
  gap: 0.5rem;
  cursor: pointer;
}

.BugIconContainer {
  cursor: pointer;
  color: red;

  &:hover {
    background-color: red;
    color: white;
  }
}

.BulbIconContainer {
  cursor: pointer;
  color: @collor-yellow-light;

  &:hover {
    background-color: @collor-yellow-light;
    color: white;
  }
}

.NoMarginFormItems {
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.TakeScreenshotButton {
  align-items: center;
  gap: 1rem;
}

.ScreenshotTakenText {
  color: @green-6;
  font-size: 18;
  font-weight: 500;
}

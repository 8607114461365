@import '../../../styles/module-base.less';
@import '../../../styles/tools.less';

.StepsList {
  font-size: @font-size-lg;
}

.QrCode__Container {
  position: relative;
  width: 75%;
  margin: 0 auto;

  .square();
}

.QrCode__ImageWrapper {
  position: relative;
}

.QrCode__ImageWrapper--Invalid {
  > img {
    opacity: 0.4;
  }
}

.QrCode__InvalidNote {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FacebookButton {
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
}
.FacebookButton:disabled,
.SubmitButton:disabled {
  background-color: #a3c0e8; /* Subdued blue for disabled button */
  color: #666666; /* Darker gray text color for disabled button */
  cursor: not-allowed; /* Cursor for disabled button */
}

.SubmitButton {
  margin-top: 10px;
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  padding: 0 24px;
}

.FormItem {
  margin: 0;
}

.PinInput {
  width: 10rem;
}

.PinContainer {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Align items vertically in the center */
}

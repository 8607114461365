@import '../../../styles/module-base.less';

.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: initial;
  gap: 4px;
  padding: 8px;
  border-radius: 6px;
  margin: 0 4px 4px 4px;

  @media (min-width: @screen-xs) {
    min-width: @screen-xs;
    width: 45%;
  }
  @media (min-width: @screen-lg) {
    gap: 5px;
    padding: 10px;
  }
}

* + .Container {
  margin-top: 10px;
}

.MessageStatus {
  display: flex;
  justify-content: flex-end;
}

.IconContainer {
  position: relative;
  line-height: 1;
}

.Container--FromMe {
  background-color: @green-4;
  align-self: flex-end;
  margin-left: 12px;
}

.Container--FromOther {
  background-color: @color-grey-light;
  align-self: flex-start;
  margin-right: 12px;
}

.Header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.MetaInfo {
  font-style: italic;
  color: @color-grey-semidark;
  font-size: @font-size-sm;
  line-height: 1;
}

.FromLabel {
  flex: 1;
  min-width: 0;
  color: @color-grey-dark;
  font-weight: bold;
  font-size: @font-size-lg;

  .Container--FromMe & {
    display: none;
  }
}

.DateLabel {
  color: @color-grey-dark;
  line-height: 1;
  align-self: flex-end;
}

.MessageStatusContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;
  gap: 0.2em;
}

.ActionButtons {
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;

  .Container:hover & {
    display: block;
  }
}

.ant-tooltip-inner {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

@import './module-base.less';

summary.email-quote-expander {
  cursor: pointer;
  user-select: none;
  list-style: none;
  background-color: @color-grey-light;
  border-radius: 4px;
  display: inline-flex;
  padding: 1px 5px 0 5px;
  height: 11px;
  line-height: 1;
  align-items: center;
  &:hover {
    background-color: @color-grey-medium;
  }
  &::-webkit-details-marker {
    display: none;
  }
}
details[data-role='email-editor-quote'][open] summary {
  // once it is expanded don't allow to collapse
  display: none;
}
details[data-role='email-editor-quote'] > details:last-child {
  // WORKAROUND-001 - for some reason is CKEditor adding <details> element with only child <br data-cke-filler="true" />.
  // this shows an empty `Details` label on UI therefore this code tries to hide it.
  display: none;
}

@import './module-base.less';

.email-quote() {
  border-left: 2px solid @color-grey-light;
  padding: 5px 10px;
}

.email-code() {
  border: 1px solid @color-grey-light;
  border-radius: 4px;
  background-color: rgba(220, 220, 220, 0.25);
  padding: 5px;
}

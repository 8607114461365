@import '../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  :global(.ant-divider-horizontal) {
    margin: 0;
  }
}

.Item {
  padding: 0 10px;
  height: 35px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  border-right: 2px solid transparent;
}

.Item--Active {
  background-color: @color-item-active;
  border-right: 2px solid @primary-color;
}
.Item--HasLink {
  cursor: pointer;

  &:hover {
    background-color: @color-item-hover;
  }
}

.Item__Content {
  min-width: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import '../../../styles/module-base.less';

.Item {
  display: flex;
  align-items: center;
  gap: 8px;

  :global(.anticon) img {
    width: 1em;
    height: 1em;
  }
}

.Item__Text {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: @font-size-sm;
  line-height: 1;
}

.Item__Actions {
  display: flex;
  align-items: center;
  gap: 6px;
}

.Icon--Success {
  color: @green-5;
}

.Icon--Loading {
  color: @blue-7;
}

.Icon--Error {
  color: @red-5;
}

.Icon--Neutral {
  color: @text-color;
}

.SignatureTabs:global(.ant-tabs) {
  overflow: visible;
}

.SignatureEditor {
  height: 250px;
  display: flex;
  flex-direction: column;

  > textarea {
    flex: 1;
  }
}

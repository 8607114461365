@import '../styles/module-base.less';

.LogoFull,
.LogoSmall {
  font-family: 'Signika Negative', sans-serif;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: @primary-color;
  user-select: none;
}

.LogoSmall {
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

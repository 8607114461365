@import '../../../styles/module-base.less';

.NoStylesMenuItem:hover {
  background-color: transparent;
}

.Item {
  &:hover {
    background-color: @color-item-hover;
    .ContextMenu {
      visibility: visible;
    }
  }

  @media (min-width: @screen-lg) {
    .ContextMenu {
      visibility: hidden;
      transition: none;
    }
  }

  text-align: left;
  justify-content: start;
  cursor: pointer;
  .ItemLabel {
    flex: 1;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
  }
}

.absolute(@horizontal, @vertical) {
  position: absolute;
  top: @vertical;
  bottom: @vertical;
  right: @horizontal;
  left: @horizontal;
}
.absolute(@all: 0) {
  .absolute(@horizontal: @all, @vertical: @all);
}

.border-radius(@size: @borderRadiusSm, @side: all) {
  .border-radius-top() {
    border-top-left-radius: @size;
    border-top-right-radius: @size;
  }
  .border-radius-bottom() {
    border-bottom-left-radius: @size;
    border-bottom-right-radius: @size;
  }
  .border-radius-left() {
    border-top-left-radius: @size;
    border-bottom-left-radius: @size;
  }
  .border-radius-right() {
    border-top-right-radius: @size;
    border-bottom-right-radius: @size;
  }
  .border-radius-inner(top) {
    .border-radius-top();
  }
  .border-radius-inner(bottom) {
    .border-radius-bottom();
  }
  .border-radius-inner(left) {
    .border-radius-left();
  }
  .border-radius-inner(right) {
    .border-radius-right();
  }
  .border-radius-inner(all) {
    .border-radius-top();
    .border-radius-bottom();
  }
  .border-radius-inner(@side);
}

.circle(@size) {
  width: @size;
  height: @size;
  border-radius: 100%;
}

.square() {
  > * {
    vertical-align: top;
    display: inline-block;
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
  }
}

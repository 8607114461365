@import '../../../styles/module-base.less';

.Container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 5px solid @input-border-color !important;

  @media print {
    padding: 10px 0;
  }

  @media (min-width: @screen-lg) {
    padding: 15px;
  }
}

.Header {
  height: auto;
  padding: 0 0.5rem;

  @media (min-width: @screen-lg) {
    padding: 0;
  }

  .Left {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.ParticipantsWrapper {
  display: flex;
  flex-direction: column;
}

.LabelRow {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.Label {
  color: @color-grey-dark;
}

.LabelValue {
  flex: 1;
  min-width: 0;
  font-weight: bold;
}

.Subject {
  font-size: @font-size-lg;
}

.DateLabel {
  color: @text-color-secondary;
  line-height: 1;
}

.Actions {
  margin-left: 1rem;
}

.BodyWrapper {
  @media (min-width: @screen-lg) {
    padding-left: 60px;
  }
}

.Attachments {
  display: flex;
  gap: 10px;
}

.Attachments__Button {
  border-radius: 50px;
}

@import '../module-base.less';

.Circle-28 {
  .circle(28px);
  border: 1px solid @color-grey-light;
  background-color: @white;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #3e6373;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Clickable {
  cursor: pointer;

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

@import '../../../styles/module-base.less';

.Item:global(.ant-list-item) {
  padding: 0;
  background-color: white;
  border: 1px solid @input-border-color;
  border-bottom-width: 0;
  cursor: pointer;
}

.ItemContent {
  padding: 8px;
}

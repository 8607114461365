@import '../../../styles/module-base.less';

.SearchBarWrapper {
  display: flex;
  width: 80%;
  max-width: @screen-xl;
  align-items: center;
  gap: 10px;
}

.SearchBar--Active {
  :global(.ant-input-affix-wrapper) {
    border-color: @primary-color;
  }
}

.FilterButton {
  > :global(.anticon) {
    font-size: @font-size-lg;
  }
}
&.FilterButton--Active {
  font-weight: bold;
  border-color: @primary-color;
  background-color: rgba(@primary-color, 0.15);
}

@import '../../../styles/module-base.less';

.Container {
  height: 100%;
  background-color: @white;
  padding: 25px;
}

.InnerContainer {
  max-width: 75%;
  display: flex;
}

.DescriptionWrapper {
  max-height: 20vh;
  overflow-y: auto;
}

.ParticipantsWrapper {
  margin: 10px 0;
}

.RSVPYesIcon {
  color: @color-green-light;
}

.RSVPNoIcon {
  color: @color-red-light;
}

.RSVPMaybeIcon {
  color: @color-grey-medium;
}

.RSVPWaitingForResponseIcon {
  color: @collor-yellow-medium;
}

.ParticipantWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: normal;
}

.IconsWrapper {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.EventInfoColumn {
  display: flex;
  flex-direction: column;
}

.ActiveIcon {
  background-color: @primary-color;
  color: @white;
}

.RSVPIcons {
  gap: 1rem;
  display: flex;
  font-size: 1rem;
  margin-top: 1rem;
}

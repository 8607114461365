@import '../../../../../styles/module-base.less';

.FormItem--Compact {
  margin-bottom: 0;
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: @color-grey-medium;
  margin: 5px 0;
}

.EditorWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
}

.CustomButtons {
  position: absolute;
  right: 20vw;
  top: initial;
  height: 40px;
  display: flex;
  align-items: center;
  bottom: 5px;

  @media (min-width: @screen-lg) {
    right: initial;
    left: 50%;
    transform: translateX(425px);
  }
}

.SendIcon {
  color: @primary-color;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Animation--Blinking {
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

// -enter and -exit are there for React Transitions, -appear and -leave are there for Ant Motion
.define-animation(
  @name,
  @animated-props,
  @start,
  @end,
  @easing: ease-in-out,
  @duration: 300ms
) {
  .@{name}-enter,
  .@{name}-appear {
    @start();
  }

  .@{name}-enter.@{name}-enter-active,
  .@{name}-appear.@{name}-appear-active {
    @end();
    transition: @animated-props @duration @easing;
  }

  .@{name}-exit,
  .@{name}-leave {
    @end();
  }

  .@{name}-exit.@{name}-exit-active,
  .@{name}-leave.@{name}-leave-active {
    @start();
    transition: @animated-props @duration @easing;
  }
}

.define-animation(~'from-bottom', transform, {transform: translateY(100%);}, {transform: none;});

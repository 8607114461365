@import '../../styles/module-base.less';

.Layout {
  height: 100vh;

  @media print {
    :global(.ant-layout-sider),
    :global(.ant-layout-header) {
      display: none;
    }
  }
}

.MainLayout {
  border-top: 1px solid @input-border-color;
}

.Sider {
  overflow-x: hidden;
  overflow-y: auto;
}

.Sider__Trigger {
  color: @primary-color;
}

.Content__Inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  min-width: 100vw;
  @media (min-width: (@screen-lg - @app-siderbar-width)) {
    min-width: @screen-lg - @app-siderbar-width;
  }
}

.SubHeader {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: @white;
}

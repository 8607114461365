@import '../styles/module-base.less';

.Container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.Button {
  border-radius: 50px;
}

.Button--NoDownload {
  cursor: initial;
}

.Button__DeleteIcon {
  font-size: 12px;
  color: @red-4;
}

.Button__ErrorIcon {
  color: @red-6;
}

@import '../../../styles/module-base.less';

.Container {
  padding-top: 10px;
  background-color: white;

  @media (min-width: @screen-lg) {
    padding-top: 20px;
  }
}

.InlineContainer {
  padding: 0 10px;
}

.Container,
.InlineContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  :global(.ant-space.ant-space-vertical) {
    flex: 1;
    display: flex;
    min-height: 0;

    > :global(.ant-space-item):last-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }
}

.Form,
.InlineContainer form {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.Form {
  :global(.ant-form-item-label) {
    width: auto;
    text-align: left;

    &::after {
      margin: 0;
    }
  }
}

.FromSelect {
  &,
  &:global(.ant-select) {
    color: @primary-color;
    width: auto;
    min-width: 250px;
  }
}

.CloseIcon {
  color: @primary-color;
}

.FormItem {
  margin-bottom: 0;

  @media (min-width: @screen-lg) {
    margin-bottom: 1rem;
  }
}
